import { removeUnwantedElements} from '../Helpers';
import { simulateLoading, uploadFilesToShare, processImageData } from '../UploadHelper';

export async function process_education_request(formData, files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, openErrorUploadDialog) {

    let hasError = false;
    let createdTicketNumber = "";

    console.log(formData);

    // Reset values
    localStorage.removeItem("error");
    localStorage.removeItem("createdTicket");

    // zoho Ticket Creation Logic
    const currentDate = new Date();
    const oneMonthFromNow = new Date(currentDate);
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    const requesterMail = localStorage.getItem("email");
    const requesterName = localStorage.getItem('name');

    if(!requesterMail || !requesterName){
        localStorage.setItem("error", `Ticket Creation failed because of missing identification!`);
        hasError = true; // Set the error variable to true
        setOpenErrorDialog(true);
        throw new Error(`Ticket Creation failed because of missing identification!`);
    }

    // Check if all required variables are defined
    const requiredVariables = [
        formData.end_customer,
        formData.end_customer_company,
        formData.end_customer_mail,
        formData.description,
        formData.trainingType,
        formData.customTrainingContent,
        formData.desiredDeliveryDate,
        formData.trainingDuration,
        formData.trainingLanguage,
        formData.trainingLocation,
        formData.expectedSolution,
        formData.attendeeCount,
        formData.training_att_skillsc,
        formData.training_att_skillze,
        formData.productVersion,
        formData.es_reasoning,

        oneMonthFromNow,
        requesterName,
        requesterMail,

    ];

    for (const [name, variable] of Object.entries(requiredVariables)) {
        if (variable === undefined) {
            console.log("Missing variable ", name);
            hasError = true; // Set the error variable to true
            localStorage.setItem("error", `Missing required values.`);
            setOpenProgressBar(false);
            setOpenErrorDialog(true);
            throw new Error(`Missing required values.`);
        }
    }

    try {

        // Create a JSON object for the request data
        const requestData = {
            ticket_type: "Education Services Request",
            ticket_subject: "AR | Training for " + formData.end_customer_company,
            priority: "Medium",
            urgency: "Medium",
            customer_mail: requesterMail,
            customer_lastname: requesterName.split(' ').pop(),
            customer_firstname: requesterName.split(' ')[0],

            // Legacy, not really needed anymore I think
            requesting_agent: requesterName,
            requester_mail: requesterMail,

            // Conditionally add ticket_short_id and ticket_full_id if present
            ...(formData.ticketData?.ticketNumber && formData.ticketData?.id ? {
                ticket_short_id: formData.ticketData?.ticketNumber,
                ticket_full_id: formData.ticketData?.id
            } : {}),

            description: formData.description,
            product_id: formData.productVersion,

            customer: formData.end_customer,
            reproduce_steps: formData.requiredHardware,
            what_do_you_want: formData.expectedSolution,
            additional_information: formData.additional_information,

            end_customer_mail: formData.end_customer_mail,
            end_customer_company: formData.end_customer_company,
            training_medium: formData.trainingType,
            training_start_date: formData.desiredDeliveryDate,
            training_type: formData.trainingContent,
            training_duration: formData.training_duration,
            training_location: formData.trainingLocation,
            training_language: formData.trainingLanguage,
            training_att_count: formData.attendeeCount,
            training_att_skillze: formData.training_att_skillze,
            training_att_skillsc: formData.training_att_skillsc,
            direct_mail_allowed: formData.directContactEndCustomer,
            es_reasoning: formData.es_reasoning,

            attachments: [],
            // Conditionally add fileshare_link if formData.ticketData.ticketNumber is present
            // Conditionally add fileshare_link based on ticketNumber
            ...(formData.ticketData.ticketNumber ? {
                fileshare_link: !isNaN(formData.ticketData.ticketNumber)
                    ? (formData.ticketData.ticketNumber > 14902
                        ? `https://fileshare.copadata.com/apps/files/?dir=/Support%20Shares/${formData.ticketData.ticketNumber}`
                        : `https://fileshare.copadata.com/apps/files/?dir=/${formData.ticketData.ticketNumber}`)
                    : `https://fileshare.copadata.com/apps/files/?dir=/Support%20Shares/${formData.ticketData.ticketNumber}`
            } : {}),
            customer_fileshare_link: `https://fileshare.copadata.com/apps/files/?dir=/${formData.ticketData.ticketNumber}`
        };

        // Completed here if no files exist.
        if (files.length === 0) {
            const progressBar = document.getElementById('progress-bar');
            const progressText = document.getElementById('progress-text');
            simulateLoading(progressBar, progressText);
        }

        processImageData(formData, requestData);
        console.log("Sending Data:", requestData);
        console.log(JSON.stringify(requestData));

        // Send the POST request as JSON
        const response = await fetch('https://fileapi.copatools.com/api/TicketData/forward-ar', {
        //const response = await fetch('https://localhost:7142/api/TicketData/forward-ar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
                'accept': 'application/json',
            },
            body: JSON.stringify(requestData), // Convert the object to JSON string
        });

        if (response.ok) {
            const data = await response.json();
            const json = JSON.parse(data);
            console.log("Response Education:");
            console.log(json);
            localStorage.setItem("createdTicket", json.id);
            createdTicketNumber = json.ticketNumber;
        } else {
            const data = await response.text();
            console.log("Error:", data);

            if (response.status === 503) {
                localStorage.setItem("error", "The Zoho API seems to be unavailable " +
                    "at the moment. We are sorry for the inconvenience, please keep " +
                    "this window open and retry in a few minutes. Error 503 - Service unavailable.");
            } else {
                localStorage.setItem("error", `Ticket Creation failed with error ${data} at SPUBO API.`);
            }

            hasError = true;
            setOpenErrorDialog(true);
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        hasError = true;
        localStorage.setItem("error", `Ticket Creation failed with error ${error.message}`);
        setOpenProgressBar(false);
        setOpenSuccessDialog(false);
        setOpenErrorDialog(true);
        return;
    }

    localStorage.removeItem('ticketId');

    if (!createdTicketNumber) {
        if (!localStorage.getItem("error")) {
            localStorage.setItem("error", `No Ticket Number set, file upload could not be completed.`);
        }

        setOpenProgressBar(false);
        setOpenSuccessDialog(false);
        setOpenErrorDialog(true);
        return;
    }

    // Handle case when no files are present
    if (files.length === 0) {
        setOpenProgressBar(false);
        setOpenSuccessDialog(true);
        setOpenErrorDialog(false);
        return;
    } else {
        // Upload files if files are present
        await uploadFilesToShare(files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, formData.ticketData?.ticketNumber, createdTicketNumber, openErrorUploadDialog);
    }
}
